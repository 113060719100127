import React, { useState, useEffect, useCallback } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import scrollToElement from 'scroll-to-element'
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col, Table } from 'react-bootstrap'
import { faBox, faCogs, faPlus, faTrash, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { TimeGrid } from '../../components/TimeGrid'
import { fetchProducts } from '../../requests/Products'
import { fetchLocations } from '../../requests/Locations'
import { ItemModal } from '../../components/ItemModal'
import { deleteItem } from "../../components/Util"

export function Device() {
    let { id } = useParams()
    let auth = useAuth()
    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()
    const [showItemModal, setShowItemModal] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [device, setDevice] = useState([])
    const [products, setProducts] = useState([])
    const [locations, setLocations] = useState([])
    const [saved, setSaved] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)

    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/devices/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false);
            setSaved(true)
            setDevice(t)
            setTimeout(function () {
                navigate({ pathname: "/admin/devices/" });
            }, 1000);
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    const fetchDevice = useCallback((id, token) => {
        fetch(process.env.REACT_APP_API + '/devices/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (r) => {
                    if (r.detail) {
                        setError({ message: r.detail })
                    } else {
                        setDevice(r)
                        setLoaded(true)
                        reset({
                            product_id: r.product_id,
                            location_id: r.location_id
                        })
                    }
                },
                (error) => {
                    setError(error)
                }
            )
    }, [reset])
    useEffect(() => {
        fetchLocations(auth.token, setLocations, setError, function () {
            fetchProducts(auth.token, setProducts, setError, function () {
                fetchDevice(id, auth.token)
            })
        })
    }, [auth.token, id, fetchDevice])

    return (
        <Container className="d-flex flex-column">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Row className="my-5">
                    <Col>
                        <h1><FontAwesomeIcon icon={faBox} /> Gerät bearbeiten</h1>
                    </Col>
                    <Col className="text-end pe-4">
                        <Button disabled={submitting} variant="primary" type="submit" onClick={() => {
                            scrollToElement('#submitbutton', {
                                offset: -80,
                                duration: 200
                            })
                        }}>
                            {submitting ? (
                                <Spinner animation="border" variant="light" size="sm"></Spinner>
                            ) : <><FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>Speichern</>}
                        </Button>
                    </Col>
                </Row>
                <Row className="w-100 my-4">
                    <Col xs="12" style={{ minHeight: 125 + "px" }}>
                        <TimeGrid loaded={loaded} start={new Date()} device={device} />
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    label="Produkt *"
                                    className="mb-3"
                                >
                                    <Form.Select {...register('product_id', { required: true, min: 1 })} aria-label="Produkt" defaultValue={products.product_id}>
                                        <option value={0}>bitte wählen</option>
                                        {products.map((p, i) => {
                                            return <option key={'productdevice' + p.id} value={p.id}>{p.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel
                                    label="Name *"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('name', { required: true })} defaultValue={device.name} type="text" placeholder="Name" required />
                                </FloatingLabel>
                                <FloatingLabel
                                    label="Seriennummer"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('serial')} defaultValue={device.serial} type="text" placeholder="Seriennummer" />
                                </FloatingLabel>
                                <FloatingLabel
                                    label="Inventarnummer"
                                    className="mb-3"
                                >
                                    <Form.Control {...register('inventar')} defaultValue={device.serial} type="text" placeholder="Inventarnummer" />
                                </FloatingLabel>
                                <FloatingLabel
                                    label="Standort *"
                                    className="mb-3"
                                >
                                    <Form.Select {...register('location_id', { required: true, min: 1 })} aria-label="Standort" defaultValue={products.location_id}>
                                        <option value={0}>bitte wählen</option>
                                        {locations.map((l, i) => {
                                            return <option key={'devicelocation' + l.id} value={l.id}>{l.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                <Form.Group className="text-start" controlId="formBasicCheckbox">
                                    <Form.Check {...register('disabled', {})} inline value={true} defaultChecked={device.disabled} type="switch" label={"Deaktiviert"} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <a href={process.env.REACT_APP_API + '/devices/qr/' + id} target="_blank" rel="noreferrer">
                                    <img className="w-100" alt="QR-Code" src={process.env.REACT_APP_API + '/devices/qr/' + id} />
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className="my-4"><FontAwesomeIcon icon={faCogs} /> Komponenten</h4>
                                <Table className="text-start" size="sm" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Seriennummer</th>
                                            <th>Inventarnummer</th>
                                            <th className="text-end">
                                                <Button className="btn-xs" onClick={() => setShowItemModal(true)}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(device.items) && device.items.map(item => (
                                            <tr key={"item" + item.id}>
                                                <td>{item.name}</td>
                                                <td>{item.serial}</td>
                                                <td>{item.inventar}</td>
                                                <td className="text-end">
                                                    <Button className="btn-xs" onClick={() => deleteItem(auth.token, device.id, 'items', item.id, item.name, setError, fetchDevice)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <p className="text-end">
                            <small>* Pflichtfelder</small>
                        </p>
                        {error && (
                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                <Alert variant="danger">
                                    <span>
                                        {error.message} {error.detail}
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        {saved && (
                            <FlashMessage duration={2000}>
                                <Alert variant="success">
                                    <span>
                                        Gerät erfolgreich bearbeitet
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        <div className="d-grid gap-2">
                            <Button id="submitbutton" disabled={submitting} variant="primary" type="submit">
                                {submitting ? (
                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                ) : <><FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>Speichern</>}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
            <ItemModal device={device} show={showItemModal} setShow={setShowItemModal} fetchDevice={fetchDevice} />
        </Container>
    )
}