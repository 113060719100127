import './scss/App.scss'
import { React, useState } from "react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import de from 'javascript-time-ago/locale/de'
import { useCookies } from "react-cookie"
import { ProvideAuth, useAuth } from './components/Auth';
import { ProvideCart } from './components/Cart';
import { ProvideConfig } from './components/Config';
import { Navigation } from './components/Navigation';
import { HelmetComponent } from './components/Helmet';
import { Footer } from './components/Footer';
import { NotFound } from './components/NotFound';
import ScrollToHashElement from "./components/ScrollToHashElement";
import { Category } from './pages/admin/Category';
import { CategoryCreate } from './pages/admin/CategoryCreate';
import { Categories } from './pages/admin/Categories';
import { Dashboard } from './pages/admin/Dashboard';
import { Device } from './pages/admin/Device';
import { AdminDevices } from './pages/admin/Devices';
import { DeviceCreate } from './pages/admin/DeviceCreate';
import { Location } from './pages/admin/Location';
import { LocationCreate } from './pages/admin/LocationCreate';
import { Locations } from './pages/admin/Locations';
import { Logs } from './pages/admin/Logs';
import { Page } from './pages/admin/Page';
import { PageCreate } from './pages/admin/PageCreate';
import { Pages } from './pages/admin/Pages';
import { Plan } from './pages/admin/Plan';
import { AdminProduct } from './pages/admin/Product';
import { ProductCreate } from './pages/admin/ProductCreate';
import { AdminProducts } from './pages/admin/Products';
import { AdminRental } from './pages/admin/Rental';
import { AdminRentals } from './pages/admin/Rentals';
import { Tag } from './pages/admin/Tag';
import { TagCreate } from './pages/admin/TagCreate';
import { Tags } from './pages/admin/Tags';
import { User } from './pages/admin/User';
import { UserCreate } from './pages/admin/UserCreate';
import { Users } from './pages/admin/Users';
import { Cms } from './pages/Cms';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Me } from './pages/Me';
import { Product } from './pages/Product';
import { Products } from './pages/Products';
import { Qr } from './pages/Qr';
import { Rentals } from './pages/Rentals';
import { Register } from './pages/Register';
import { Confirmation } from './pages/Confirmation';
import { ChangeEmail } from './pages/ChangeEmail';

TimeAgo.addDefaultLocale(de)

export default function App() {
    const [cookies, setCookie] = useCookies(['essential', 'theme'])
    const [theme, setTheme] = useState(cookies['theme'] ? cookies['theme'] : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    const updateTheme = (theme) => {
        if (cookies['essential']) {
            setCookie('theme', theme, {
                path: '/',
                sameSite: true,
                secure: true
            })
        }
        setTheme(theme);
    }

    return (
        <ProvideConfig>
            <ProvideAuth>
                <ProvideCart>
                    <Router basename={process.env.REACT_APP_BASE}>
                        <HelmetComponent theme={theme} />
                        <ScrollToHashElement />
                        <Navigation setTheme={updateTheme} theme={theme}/>
                        <main className="mt-5 pt-5" role="main">
                            <Routes>
                                <Route path="/admin/categories/:id" element={<MitarbeiterRoute><Category /></MitarbeiterRoute>} />
                                <Route path="/admin/categories/create" element={<MitarbeiterRoute><CategoryCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/categories" element={<MitarbeiterRoute><Categories /></MitarbeiterRoute>} />
                                <Route path="/admin/devices/create/:product_id" element={<MitarbeiterRoute><DeviceCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/devices/create" element={<MitarbeiterRoute><DeviceCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/devices/product/:product_id" element={<MitarbeiterRoute><AdminDevices /></MitarbeiterRoute>} />
                                <Route path="/admin/devices/:id" element={<MitarbeiterRoute><Device /></MitarbeiterRoute>} />
                                <Route path="/admin/devices" element={<MitarbeiterRoute><AdminDevices /></MitarbeiterRoute>} />
                                <Route path="/admin/locations/create" element={<AdminRoute><LocationCreate /></AdminRoute>} />
                                <Route path="/admin/locations/:id" element={<AdminRoute><Location /></AdminRoute>} />
                                <Route path="/admin/locations" element={<AdminRoute><Locations /></AdminRoute>} />
                                <Route path="/admin/logs" element={<AdminRoute><Logs /></AdminRoute>} />
                                <Route path="/admin/pages/create" element={<AdminRoute><PageCreate /></AdminRoute>} />
                                <Route path="/admin/pages/:id" element={<AdminRoute><Page /></AdminRoute>} />
                                <Route path="/admin/pages" element={<AdminRoute><Pages /></AdminRoute>} />
                                <Route path="/admin/plan/:startdate" element={<MitarbeiterRoute><Plan /></MitarbeiterRoute>} />
                                <Route path="/admin/plan" element={<MitarbeiterRoute><Plan /></MitarbeiterRoute>} />
                                <Route path="/admin/products/create" element={<MitarbeiterRoute><ProductCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/products/:id" element={<MitarbeiterRoute><AdminProduct /></MitarbeiterRoute>} />
                                <Route path="/admin/products" element={<MitarbeiterRoute><AdminProducts /></MitarbeiterRoute>} />
                                <Route path="/admin/rentals/:id" element={<MitarbeiterRoute><AdminRental /></MitarbeiterRoute>} />
                                <Route path="/admin/rentals" element={<MitarbeiterRoute><AdminRentals /></MitarbeiterRoute>} />
                                <Route path="/admin/tags/create" element={<MitarbeiterRoute><TagCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/tags/:id" element={<MitarbeiterRoute><Tag /></MitarbeiterRoute>} />
                                <Route path="/admin/tags" element={<MitarbeiterRoute><Tags /></MitarbeiterRoute>} />
                                <Route path="/admin/users/create" element={<MitarbeiterRoute><UserCreate /></MitarbeiterRoute>} />
                                <Route path="/admin/users/:uuid" element={<MitarbeiterRoute><User /></MitarbeiterRoute>} />
                                <Route path="/admin/users" element={<MitarbeiterRoute><Users /></MitarbeiterRoute>} />
                                <Route path="/admin" element={<MitarbeiterRoute><Dashboard theme={theme} /></MitarbeiterRoute>} />
                                <Route path="/changemail/:uuid/:hash" element={<ChangeEmail />} />
                                <Route path="/confirmation/:uuid" element={<Confirmation />} />
                                <Route exact path="/rentals" element={<PrivateRoute><Rentals /></PrivateRoute>} />
                                <Route exact path="/register" element={<Register />} />
                                <Route exact path="/home" element={<Home />} />
                                <Route exact path="/login" element={<Login />} />
                                <Route exact path="/me" element={<PrivateRoute><Me /></PrivateRoute>} />
                                <Route path="/products/category/:category_id" element={<Products />} />
                                <Route path="/products/tag/:tag_id" element={<Products />} />
                                <Route path="/products/:id" element={<Product />} />
                                <Route exact path="/products" element={<Products />} />
                                <Route path="/qr/:id/:device_id" element={<Qr />} />
                                <Route path="/qr/:id" element={<Qr />} />
                                <Route exact path="/:slug" element={<Cms />} />
                                <Route exact path="/" element={<Home />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </main>
                        <Footer />
                    </Router>
                </ProvideCart>
            </ProvideAuth>
        </ProvideConfig>
    );
}

function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    let location = useLocation();
    return auth.scopes.includes("me") ? children : (
        <Navigate
            to={{
                pathname: "/login",
                state: { from: location }
            }}
        />
    );
}

function MitarbeiterRoute({ children, ...rest }) {
    let auth = useAuth();
    let location = useLocation();
    return auth.scopes.includes("mitarbeiter") ? children : (
        <Navigate
            to={{
                pathname: "/login",
                state: { from: location }
            }}
        />
    );
}

function AdminRoute({ children, ...rest }) {
    let auth = useAuth();
    let location = useLocation();
    return auth.scopes.includes("admin") ? children : (
        <Navigate
            to={{
                pathname: "/login",
                state: { from: location }
            }}
        />
    );
}