import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Modal, FloatingLabel, Form, Button, Alert, Row, Col } from 'react-bootstrap'
import { useAuth } from './Auth'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlashMessage from './FlashMessage'
import { FileUploader } from "react-drag-drop-files"
import { isHttpValid } from './validation/Url'

export function ManualModal({ product, show, setShow, fetchProduct, setProduct, setIsLoaded }) {
    let auth = useAuth();
    const fileTypes = ["JPG", "PNG", "GIF", "WEBP", "PDF"]
    const { register, handleSubmit, setValue, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [uploading, setUploading] = useState(false)
    const onSubmit = data => {
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/manuals/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setShow(false)
            setValue('name', '')
            setValue('url', '')
            fetchProduct(product.id, auth.token, setProduct, setError, null, null, setIsLoaded)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    const uploadFile = f => {
        setUploading(true)
        const formData = new FormData()
        formData.append('file', f)
        formData.append('product_id', product.id)
        fetch(process.env.REACT_APP_API + '/manuals/document/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: formData
        }).then(res => {
            if (!res.ok) {
                throw new Error('Dokument konnte nicht gespeichert werden')
            }
            return res.json()
        }).then(d => {
            setUploading(false)
            setValue('name', '')
            setValue('url', '')
            setShow(false)
            fetchProduct(product.id, auth.token, setProduct, setError, null, null, setIsLoaded)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setUploading(false)
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <input {...register('product_id', { required: true })} type="hidden" value={product.id} />
                <Modal.Header closeButton>
                    <Modal.Title>Manual hinzufügen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel
                        label="Name *"
                        className="mb-3"
                    >
                        <Form.Control {...register('name', { required: true })} type="text" placeholder="Name" required />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Link *"
                        className="mb-3"
                    >
                        <Form.Control className={errors.url ? 'is-invalid' : ''} {...register('url', { required: true, validate: value => isHttpValid(value) })} type="text" placeholder="Link" required />
                        {errors.url && <div className="invalid-feedback text-start">Bitte überprüfen Sie den Link</div>}
                    </FloatingLabel>
                    <p className="text-center my-0">oder</p>
                    <FileUploader classes="dropzone w-100 p-4 mt-3" disabled={uploading} handleChange={(t) => uploadFile(t)} name="doc" types={fileTypes} maxSize={25} children={
                        <Row>
                            <Col xs="12" className="text-center">
                                <FontAwesomeIcon icon={faCloudArrowUp} />
                                <p>Dokument per Drag & Drop hochladen oder</p>
                                <Button>Computer durchsuchen</Button>
                            </Col>
                        </Row>
                    } />
                    {error && (
                        <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                            <Alert variant="danger">
                                <span>
                                    {error.message} {error.detail}
                                </span>
                            </Alert>
                        </FlashMessage>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Schließen
                    </Button>
                    <Button variant="primary" disabled={submitting} type="submit">Speichern</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}