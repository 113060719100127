import React, { useState } from "react"
import {
    Navigate, useLocation, Link
} from "react-router-dom";
import { useForm } from "react-hook-form"
import { Alert, Button, Spinner, FloatingLabel, Form, Row, Col } from 'react-bootstrap'
import { useAuth } from './Auth'
import { useConfig } from './Config'
import FlashMessage from './FlashMessage'

export function RegisterForm() {
    let auth = useAuth()
    let config = useConfig()
    let location = useLocation();
    const { register, handleSubmit, getValues, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [thanks, setThanks] = useState(false)

    const onSubmit = data => {
        setSubmitting(true);
        data.plz = "";
        data.ort = "";
        data.street = "";
        data.streetnum = "";
        data.tel = "";
        fetch(process.env.REACT_APP_API + '/users/register', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(data => {
            setSubmitting(false);
            if (data.uuid.length > 0) {
                setThanks(true);
            }
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    if (!config.registration) {
        return <Alert variant="danger">
            <p>Die Registrierung ist im Moment leider deaktiviert.</p>
        </Alert>
    }
    if (auth.scopes.includes("me")) {
        <Navigate
            to={{
                pathname: "/me",
                state: { from: location }
            }}
        />
    }
    if (thanks) {
        return (
            <Row>
                <Col className="text-center">
                    <h2>Email bestätigung</h2>
                    <p>Wir haben Ihnen an die eingegebene Email eine Nachricht gesendet mit der Bitte, den darin enthaltenen Link aufzurufen, um Ihre Anmeldung abzuschließen.</p>
                </Col>
            </Row>
        )
    }
    return (
        <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel
                label="Email *"
                className="mb-3"
            >
                <Form.Control {...register('email', { required: true })} type="email" placeholder="name@example.com" autoFocus required />
            </FloatingLabel>
            <FloatingLabel
                label="Passwort *"
                className="mb-3"
            >
                <Form.Control {...register('password', { required: true, minLength: 8 })} minLingth={8} type="password" placeholder="Passwort *" required />
            </FloatingLabel>
            {errors.password && <Alert variant="danger" className="text-start">Bitte verwenden Sie mindestens 8 Zeichen</Alert>}
            <FloatingLabel
                label="Passwort Verfifikation *"
                className="mb-3"
            >
                <Form.Control {...register('password2', { required: true, minLength: 8, validate: value => value === getValues("password") })} minLingth={8} type="password" placeholder="Passwort Verfifikation" required />
            </FloatingLabel>
            {errors.password2 && <Alert variant="danger" className="text-start">Bitte überprüfen Sie die Schreibweise Ihres Passwortes</Alert>}
            <FloatingLabel
                label="Vorname *"
                className="mb-3"
            >
                <Form.Control {...register('firstname', { required: true })} type="text" placeholder="Vorname" required />
            </FloatingLabel>
            <FloatingLabel
                label="Nachname *"
                className="mb-3"
            >
                <Form.Control {...register('lastname', { required: true })} type="text" placeholder="Nachname" required />
            </FloatingLabel>
            <FloatingLabel
                label="Einrichtung"
                className="mb-3"
            >
                <Form.Control {...register('einrichtung', {})} type="text" placeholder="Einrichtung" />
            </FloatingLabel>
            <Form.Group className="text-start" controlId="formBasicCheckbox">
                <Form.Check {...register('agb', { required: true })} type="checkbox" label={
                    <p className="w-100">Ich habe die <Link to="/agb" title="AGB">AGB</Link> gelesen</p>
                } value={true} />
            </Form.Group>
            <p className="text-end">
                <small>* Pflichtfelder</small>
            </p>

            {error && (
                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                    <Alert variant="danger">
                        <span>
                            {error.message} {error.detail}
                        </span>
                    </Alert>
                </FlashMessage>
            )}
            <div className="d-grid gap-2">
                <Button disabled={submitting} variant="primary" type="submit">
                    {submitting ? (
                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                    ) : "Registrieren"}
                </Button>
            </div>
        </form>
    )
}